import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap'
import randomColor from 'randomcolor';
import './gallery.css'

class Gallery extends Component {

// gs1.jpeg
  render() {
    const url = 'https://s3-ap-southeast-1.amazonaws.com/doctoroneworld-dev/hgolden+spoon/'
    const image = ['gs1.jpeg', 'gs2.jpeg', 'gs3.jpeg', 'gs4.jpeg', 'gs5.jpeg', 'gs6.jpeg', 'gs7.jpeg', 'gs9.jpeg', 'gs9.jpeg', 'gs10.jpeg', 'gs11.jpeg', 'gs12.jpeg', 'gs13.jpeg', 'gs14.jpeg', 'gs15.jpeg', 'gs16.jpeg', 'gs17.jpeg', 'gs18.jpeg', 'gs19.jpeg', 'gs20.jpeg', 'gs21.jpeg', 'gs22.jpeg', 'gs23.jpeg', 'gs24.jpeg', 'gs25.jpeg', 'gs26.jpeg', 'gs27.jpeg', 'gs28.jpeg', 'gs29.jpeg', 'gs30.jpeg', 'gs31.jpeg', 'gs32.jpeg', 'gs33.jpeg']
    return (
      <div>
        <Grid>
          <Row>
          {image.map((eachImage, index) => {
            return <Col xs={12} md={4} key={index}><div className="image" style={{ backgroundImage: `url('${url+eachImage}')`, boxShadow: `3px 5px ${randomColor({ luminosity: 'light'})}`}}></div></Col>
          })}
          </Row>
        </Grid>
      </div>
    );
  }

}

export default Gallery;
