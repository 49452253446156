import React from 'react'
import { Navbar, Nav, MenuItem, NavDropdown, NavItem, Modal, Popover, Tooltip, Button, OverlayTrigger, Grid, Row, Col } from 'react-bootstrap'
import {
  BrowserRouter as Router,
  Route,
  NavLink,
} from 'react-router-dom'
import randomColor from 'randomcolor';
import Dish from './dish'
import Gallery from './gallery';
import { Link, animateScroll as scroll } from "react-scroll";
import './home.css'
const pdfMenu = require('../images/menu.pdf')

class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      category: 'cold',
      dish: [
        { name: '5 Special Varieties Cold Platter', price: ['$120'] },
        { name: 'Crispy Fried Baby Squid In Rojar Sauce', price: ['$15', '$20', '$25'] },
        { name: 'Teochew Style Ngo Hiong', price: ['$12', '$18', '$24'] },
        { name: 'Jelly Fish In Thai Sauce', price: ['$15', '$20', '$25'] },
        { name: 'Crispy Fried Fresh Mushroom w/ Salt & Pepper', price: ['$12', '$18', '$24'] },
        { name: 'You Tiao', price: ['$12', '$18', '$24'] },
        { name: 'Shrimp Paste Green Beans', price: ['$12', '$18', '$24'] },
        { name: 'Special Prawn Roll with Salted Egg', price: ['$12', '$18', '$24'] }
      ],
      gallery: false,
      modalPop: false
    };
    this.dishlist = this.dishlist.bind(this);
    this.gallery = this.gallery.bind(this)
  }

  gallery() {
    let state = this.state
    this.setState({
      gallery: !state.gallery
    })
  }

  modalPop() {
    let state = this.state
    console.log('state - > ', state);
    this.setState({
      modalPop: !state.modalPop
    })
  }

  dishlist(params) {
    const that = this
    switch (params) {
      case 'cold':
      that.setState({
        category: 'cold',
        dish: [
          { name: '5 Special Varieties Cold Platter', price: ['$120'] },
          { name: 'Crispy Fried Baby Squid In Rojar Sauce', price: ['$15', '$20', '$25'] },
          { name: 'Teochew Style Ngo Hiong', price: ['$12', '$18', '$24'] },
          { name: 'Jelly Fish In Thai Sauce', price: ['$15', '$20', '$25'] },
          { name: 'Crispy Fried Fresh Mushroom w/ Salt & Pepper', price: ['$12', '$18', '$24'] },
          { name: 'You Tiao', price: ['$12', '$18', '$24'] },
          { name: 'Shrimp Paste Green Beans', price: ['$12', '$18', '$24'] },
          { name: 'Special Prawn Roll with Salted Egg', price: ['$12', '$18', '$24'] }
        ],
        gallery: false
      })
        break;
      case 'shark':
        that.setState({
          category: 'shark',
          dish: [
            { name: 'Shark\'s Fin w/ Crab Meat Soup', price: ['$60', '$90', '$120'] },
            { name: 'Crabmeat w/ Fish Maw Soup', price: ['$40', '$60', '$80'] },
            { name: 'Seafood Potage w/ Tofu', price: ['$30', '$45', '$60'] },
            { name: 'Hot & Sour Soup', price: ['$30', '$45', '$60'] },
            { name: 'Sweet Corn w/ Crab Meat Soup', price: ['$24', '$36', '$48'] },
          ],
          gallery: false
        })
      break;
      case 'crab':
        that.setState({
          category: 'crab',
          dish: [
            { name: 'Bamboo Clams', price: ['$12'] },
            { name: 'Golden Spoon Bee Hoon Crab', price: [] },
            { name: 'Black Pepper Crab', price: [] },
            { name: 'Chilli Crab', price: [] },
            { name: 'Salted Egg Crab', price: [] },
            { name: 'Cold Crab', price: [] },
            { name: 'Si Chuan Style Crab', price: [] },
          ],
          gallery: false
        })
      break;
      // case 'familyB':
      //   that.setState({
      //     category: 'familyB',
      //     dish: [
      //       { name: 'Prosperity Salmon Yu Sheng', price: [] },
      //       { name: 'Fresh Crab Meat w/ Fish Maw Soup', price: [] },
      //       { name: 'Golden Spoon Cripsy Fried Chicken', price: [] },
      //       { name: 'Steamed Red Grouper in H.K. Style', price: [] },
      //       { name: 'Panfried Prawn w/ Cereal', price: [] },
      //       { name: 'Braised Seacucumber w/ Mushroom', price: [] },
      //       { name: 'Panfried Mixed Vege Wrap w/ Tofu Skin', price: [] },
      //       { name: 'Frangant Rice w/ Chinese Sausage (wrapped in lotus leaf)', price: [] },
      //       { name: 'Yam Paste with Ginko Nuts', price: [] },
      //       { name: '10 pax', price: ['$488'] },
      //     ],
      //     gallery: false
      //   })
      // break;
      // case 'familyC':
      //   that.setState({
      //     category: 'familyC',
      //     dish: [
      //       { name: 'Prosperity Salmon Yu Sheng', price: [] },
      //       { name: 'Shark\'s Fin w/ Dry Scallop and Crab Meat', price: [] },
      //       { name: 'Golden Dragon Chicken', price: [] },
      //       { name: 'Steamed Live Soon Hock (Home Style)', price: [] },
      //       { name: 'Panfried Salted Egg Prawn', price: [] },
      //       { name: 'Braised Abalone, Seacucumber, Fish Maw, Scallop w/ Superior Stock', price: [] },
      //       { name: 'Mixed 8 Kind of Vege Wrap w/ Tofu Skin', price: [] },
      //       { name: 'Golden Spoon Crab Bee Hoon', price: [] },
      //       { name: 'Yam Paste with Ginko Nuts', price: [] },
      //       { name: '10 pax', price: ['$728'] },
      //     ],
      //     gallery: false
      //   })
      // break;
      // case 'familyD':
      //   that.setState({
      //     category: 'familyD',
      //     dish: [
      //       { name: 'Prosperity Salmon Yu Sheng', price: [] },
      //       { name: 'Suckling Pig 1/2', price: [] },
      //       { name: 'Superior Shark\'s Fin', price: [] },
      //       { name: 'Golden Spoon Crispy Duck', price: [] },
      //       { name: 'Panfried Prawn in Black Sauce', price: [] },
      //       { name: 'Steamed Live Soon Hock w/ Two Kind of Garlic', price: [] },
      //       { name: 'Braised Abalone, Seacucumber, Fish Maw, Scallop w/ Superior Stock', price: [] },
      //       { name: 'Mixed 8 Kind of Vege Wrap w/ Tofu Skin', price: [] },
      //       { name: 'Golden Spoon Crab Bee Hoon', price: [] },
      //       { name: 'Yam Paste with Ginko Nuts', price: [] },
      //       { name: '10 pax', price: ['$1088'] },
      //     ],
      //     gallery: false
      //   })
      break;
      case 'prawn':
        that.setState({
          category: 'prawn',
          dish: [
            { name: 'Claypot Drunken Prawn w/ Herbal Soup', price: ['$24', '$36', '$48'] },
            { name: 'Garlic Steamed Prawn', price: ['$24', '$36', '$48'] },
            { name: 'Steamed Prawn', price: ['$24', '$36', '$48'] },
            { name: 'Salt & Pepper Prawn', price: ['$24', '$36', '$48'] },
            { name: 'Cereal Prawn', price: ['$24', '$36', '$48'] },
            { name: 'Panfried Prawn in Sichuan Style', price: ['$24', '$36', '$48'] },
            { name: 'Panfried Prawn w/ Salad Sauce', price: ['$24', '$36', '$48'] },
            { name: 'Salted Egg Prawn', price: ['$24', '$36', '$48'] },
            { name: 'Pan Fried Prawn In Black Sauce', price: ['$24', '$36', '$48'] },
          ],
          gallery: false
        })
      break;
      case 'fish':
        that.setState({
          category: 'fish',
          dish: [
            { name: 'Steamed in HK Style', price: [] },
            { name: 'Steamed in Teochew Style', price: [] },
            { name: 'Steamed in Assam Sauce', price: [] },
            { name: 'Thai and Spicy Sauce', price: [] },
            { name: 'Crispy Fried w/ Soya Sauce', price: [] },
            { name: 'Curry Fish Head', price: ['$32'] },
            { name: 'Assam Steamed Fsih Head', price: ['$32'] },
            { name: 'Braised Fish Head w/ Bittergourd & Black Bean Sauce', price: ['$32'] },
            { name: 'Red Grouper Steamboat', price: ['$10 per 100g'] },
            { name: 'Cod Fish', price: ['$12 per 100g'] },
          ],
          gallery: false
        })
      break;
      case 'meat':
        that.setState({
          category: 'meat',
          dish: [
            { name: 'Golden Dragon Chicken (Order in Advance)', price: ['$40'] },
            { name: 'Golden Spoon Crispy Chicken', price: ['$16', '$32'] },
            { name: 'Crispy Cereal w/ Pumpkin Chicken', price: ['$16', '$32'] },
            { name: 'Crispy Fried Shrimp Paste Chicken', price: ['$12', '$18', '$24'] },
            { name: 'Braised Chicken in Taiwan Style', price: ['$12', '$18', '$24'] },
            { name: 'Panfired Chicken w/ Salted Egg', price: ['$15', '$20', '$25'] },
            { name: 'Panfried Coffee Rib', price: ['$15', '$20', '$25'] },
            { name: 'Panfried Chinese Style Rib', price: ['$15', '$20', '$25'] },
            { name: 'Salt & Pepper Rib', price: ['$15', '$20', '$25'] },
            { name: 'Sweet & Sour Pork', price: ['$12', '$18', '$24'] },
            { name: 'Deer Meat w/ Ginger & Spring Onion', price: ['$20', '$30', '$40'] },
            { name: 'Braised Pork w/ Yam in Home Style', price: ['$15', '$30'] },
            { name: 'Crispy Fried Trotter w/ Thai Sauce', price: ['$28'] },
          ],
          gallery: false
        })
      break;
      case 'vegetable':
        that.setState({
          category: 'vegetable',
          dish: [
            { name: 'Kai Lan', price: ['$12', '$18', '$24'] },
            { name: 'Broccoli', price: ['$15', '$20', '$25'] },
            { name: 'Local Spinach w/ 3 Kinds of Egg', price: ['$12', '$18', '$24'] },
            { name: 'Green Dragon Vege', price: ['$16', '$24', '$32'] },
            { name: 'Spinach', price: ['$10', '$15', '$20'] },
            { name: 'Kang Kong', price: ['$10', '$15', '$20'] },
            { name: 'Asparagus', price: ['$18', '$24', '$36'] },
            { name: 'Small Cabbage', price: ['$12', '$18', '$24'] },
            { name: 'Romaine', price: ['$12', '$18', '$24'] },
            { name: '4 Kind of Vege x/ XO Sauce', price: ['$16', '$24', '$32'] },
          ],
          gallery: false
        })
      break;
      case 'tofu':
        that.setState({
          category: 'tofu',
          dish: [
            { name: 'Crispy Fried Tofu w/ Preserved Vege & Pork', price: ['$12', '$18', '$24'] },
            { name: 'Claypot Tofu w/ Seafood & Vegetable', price: ['$15', '$20', '$25'] },
            { name: 'Deepfried Tofu w/ Cereal & Pumpkin Sauce', price: ['$12', '$18', '$24'] },
            { name: 'Braised Tofu w/ Dry Scallop & Mushroom', price: ['$15', '$20', '$25'] },
            { name: 'Tofu w/ Thai Sauce', price: ['$12', '$18', '$24'] },
            { name: 'Tofu w/ Fresh Scallop in Chilli Crab Sauce', price: ['$22', '$36', '$48'] }
          ],
          gallery: false
        })
      break;
      case 'rice':
        that.setState({
          category: 'rice',
          dish: [
            { name: 'Seafood Hor Fun, Bee Hoon Or Mee', price: ['$8', '$12', '$16'] },
            { name: 'Dried Fried Seafood Bee Hoon', price: ['$8', '$12', '$16'] },
            { name: 'Special Birthday Noodles', price: ['$10', '$15', '$20'] },
            { name: 'Hong Kong Noodles', price: ['$8', '$12', '$16'] },
            { name: 'Braised Hockien Mee In Claypot', price: ['$10', '$15', '$20'] },
            { name: 'Hor Fun w/ Sliced Fish & Beans Sprout', price: ['$10', '$15', '$20'] },
            { name: 'Mee Goreng', price: ['$8', '$12', '$16'] },
            { name: 'Seafood Fried Rice', price: ['$8', '$12', '$16'] },
            { name: 'Mui Fan', price: ['$10', '$15', '$20'] },
            { name: 'Salted Fish & Chicken Fried Rice', price: ['$8', '$12', '$16'] },
            { name: 'Mee Sua', price: ['$10', '$15', '$20'] },
          ],
          gallery: false
        })
      break;
      case 'dessert':
        that.setState({
          category: 'dessert',
          dish: [
            { name: 'Chilled Lemon Grass Jelly w/ Fruits Cocktails', price: ['$3'] },
            { name: 'Teochew O-ni Gingko Nut', price: ['$18', '$24', '$30'] },
            { name: 'Mango Pudding', price: ['$5'] },
            { name: 'Ice Cream', price: ['$3'] },
            { name: 'Coke', price: ['$2'] },
            { name: 'Greentea', price: ['$2'] },
            { name: '100plus', price: ['$2'] },
            { name: 'Barley Cold/Hot', price: ['$2'] },
            { name: 'Water Chestnut', price: ['$2'] },
            { name: 'Fresh Lime Juice', price: ['$2'] },
            { name: 'Tiger Beer', price: ['$9'] },
            { name: 'Heineken', price: ['$10'] },
            { name: 'Guinness Can', price: ['$6'] },
            { name: 'Casa Tilva Red Wine', price: ['$68'] },
            { name: '8 Year Aged Shao Hsing Chia Fan Chiew', price: ['$60'] },
          ],
          gallery: false
        })
      break;
      default:

    }
  }

  render () {
    const { gallery } = this.state;
    return (
      <div id="homepage">
        <Navbar fixedTop expanded={this.state.expanded}>
          <Navbar.Header>
            <Navbar.Brand>
              <a href='/'>De Golden Spoon</a>
            </Navbar.Brand>
            <Navbar.Toggle onClick={() => { 
              this.setState({expanded: !this.state.expanded})
            }}/>
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav pullRight>
              <NavItem eventKey={1} href={`/`}>Home</NavItem>
              {/* <NavItem eventKey={2} href={`/menu`}>Menu</NavItem> */}
              {/* <NavDropdown eventKey={3} title="Menu" id="basic-nav-dropdown"> */}
                {/* <MenuItem
                  onClick={() => { this.dishlist('familyB')}}
                  eventKey={3.1}
                >Family Happiness Set (10pax@ $488)</MenuItem>
                <MenuItem
                  onClick={() => { this.dishlist('familyC')}}
                  eventKey={3.1}
                >Good Luck Set (10pax@ $728)</MenuItem>
                <MenuItem
                  onClick={() => { this.dishlist('familyD')}}
                  eventKey={3.1}
                >Rich Years Set (10pax@ $1088)</MenuItem> */}
                {/* <MenuItem
                  //onClick={() => {
                  //  this.dishlist('cold')
                  // }}
                  eventKey={3.1}
                >
                  <Link
                    style={{color: '#777'}}
                    to="anchor" 
                    smooth={true} 
                    duration={500} 
                    offset={-70}
                    onClick={() => {
                      this.dishlist('cold')
                      console.log('setting state');
                      this.setState({expanded: false})
                    }}
                  >
                    Cold Dish | Appetizer
                  </Link>
                </MenuItem> */}
                {/* <MenuItem
                  eventKey={3.2}
                >
                  <Link
                    style={{color: '#777'}}
                    to="anchor" 
                    smooth={true} 
                    duration={500} 
                    offset={-70}
                    onClick={() => {
                      this.setState({expanded: false})
                      this.dishlist('shark')
                    }}
                  >
                    Shark's Fin | Thick Soup
                  </Link>
                </MenuItem> */}
                {/* <MenuItem
                  eventKey={3.3}
                >
                  <Link
                    style={{color: '#777'}}
                    to="anchor" 
                    smooth={true} 
                    duration={500} 
                    offset={-70}
                    onClick={() => {
                      this.setState({expanded: false})
                      this.dishlist('crab')
                    }}
                  >
                    Crab
                  </Link>
                </MenuItem> */}
                {/* <MenuItem
                  eventKey={3.4}
                ><Link
                  style={{color: '#777'}}
                  to="anchor" 
                  smooth={true} 
                  duration={500} 
                  offset={-70}
                  onClick={() => {
                    this.setState({expanded: false})
                    this.dishlist('prawn')
                  }}
                >
                  Prawn
                </Link>
                </MenuItem> */}
                {/* <MenuItem
                  eventKey={3.5}
                ><Link
                  style={{color: '#777'}}
                  to="anchor" 
                  smooth={true} 
                  duration={500} 
                  offset={-70}
                  onClick={() => {
                    this.setState({expanded: false})
                    this.dishlist('fish')
                  }}
                >Fish</Link>
                </MenuItem> */}
                {/* <MenuItem
                  eventKey={3.6}
                  onClick={() => { 
                    this.setState({expanded: false})
                    this.dishlist('meat')
                  }}
                ><Link
                  style={{color: '#777'}}
                  to="anchor" 
                  smooth={true} 
                  duration={500} 
                  offset={-70}
                  onClick={() => { 
                    this.setState({expanded: false})
                    this.dishlist('meat')
                  }}
                >Meat</Link>
                </MenuItem> */}
                {/* <MenuItem divider /> */}
                {/* <MenuItem
                  eventKey={3.7}
                ><Link
                  style={{color: '#777'}}
                  to="anchor" 
                  smooth={true} 
                  duration={500} 
                  offset={-70}
                  onClick={() => { 
                    this.setState({expanded: false})
                    this.dishlist('vegetable')
                  }}
                >Vegetable</Link>
                </MenuItem> */}
                {/* <MenuItem
                  eventKey={3.8}
                ><Link
                  style={{color: '#777'}}
                  to="anchor" 
                  smooth={true} 
                  duration={500} 
                  offset={-70}
                  onClick={() => { 
                    this.setState({expanded: false})
                    this.dishlist('tofu')
                  }}
                >Tofu</Link>
                </MenuItem> */}
                {/* <MenuItem
                  eventKey={3.9}
                ><Link 
                  style={{color: '#777'}}
                  to="anchor" 
                  smooth={true} 
                  duration={500} 
                  offset={-70}
                  onClick={() => { 
                    this.setState({expanded: false})
                    this.dishlist('rice')
                  }}
                >Rice & Noodle</Link></MenuItem> */}
                {/* <MenuItem
                  eventKey={3.7}
                >
                  <Link
                    style={{color: '#777'}}
                    to="anchor" 
                    smooth={true} 
                    duration={500} 
                    offset={-70}
                    onClick={() => { 
                      this.setState({expanded: false})
                      this.dishlist('dessert')
                    }}
                  >Dessert | Drink | Beer | Wine
                  </Link>
                </MenuItem> */}
              {/* </NavDropdown> */}
              {/* <NavItem
                eventKey={3}
              >
                <Link 
                  to="anchor" 
                  smooth={true} 
                  duration={500} 
                  offset={-70}
                  onClick={() => {
                    this.gallery()
                    this.setState({expanded: false})
                  }}
                  style={{color: '#777'}}
                >
                    Gallery
                </Link>
              </NavItem> */}
              <NavItem
                eventKey={2} href={pdfMenu} onClick={() => {this.modalPop()}}>Menu
              </NavItem>
              <NavItem
                eventKey={3} href={`tel:+6591185544`}>Call Us
              </NavItem>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Grid>
          <Row className="show-grid row">
            <Col xs={12} md={4}>
              <div className={'welcomeMessage'} style={{boxShadow: `6px 3px 3px ${randomColor({ luminosity: 'light'})}`}}>
                <h1>Welcome <br/> to <br/> De Golden Spoon!</h1>
                <p>62 Seng Poh Ln #01-11, 160062</p>
                <p><strong>For Reservations</strong>: <a
                  className='cta'
                  href={`tel:+6591185544`}
                  style={{ textDecroration: 'none', fontWeight: 'bold', fontSize: '1.1em', color: `white`, backgroundColor: `${randomColor({ luminosity: 'dark'})}`, padding: '0.5em', borderRadius: '1.5em', boxShadow: `3px 3px 2px 1px rgba(134, 134, 134, 0.53)` }}
                  >9118 5544</a></p>
              </div>
            </Col>
            <Col xs={12} md={8}>
              <div style={{boxShadow: `6px 3px 3px ${randomColor({ luminosity: 'light'})}`}} className={'crabPicture'}>
              </div>
            </Col>
          </Row>
          <Row className={"row"}>
            <Col xs={12} md={4}>
              <div style={{boxShadow: `6px 3px 3px ${randomColor({ luminosity: 'light'})}`}} className={'spinach'}>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div style={{boxShadow: `6px 3px 3px ${randomColor({ luminosity: 'light'})}`}} className={'welcomeMessage'}>
                <h1>Open Daily</h1>
                <h2>Lunch</h2>
                <p>11AM – 2:30PM</p>
                <h2>Dinner</h2>
                <p>5PM – 10:30PM</p>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div style={{boxShadow: `6px 3px 3px ${randomColor({ luminosity: 'light'})}`}} className={'blackpeppercrab'}>
              </div>
            </Col>
          </Row>
      </Grid>
{ false && !gallery && <Grid>
        <Row className="show-grid row">
          <div id='dishTitle'>
          {/* {this.state.category === 'familyB' ? <h1 style={{ fontWeight: 'bold', color: `${randomColor({ luminosity: 'light'})}` }}>Family Happiness Set (10pax@ $488)</h1> : ''}
          {this.state.category === 'familyC' ? <h1 style={{ fontWeight: 'bold', color: `${randomColor({ luminosity: 'light'})}` }}>Good Luck Set (10pax@ $728)</h1> : ''}
          {this.state.category === 'familyD' ? <h1 style={{ fontWeight: 'bold', color: `${randomColor({ luminosity: 'light'})}` }}>Rich Years Set (10pax@ $1088)</h1> : ''} */}
          {this.state.category === 'cold' ? <h1 style={{ fontWeight: 'bold', color: `${randomColor({ luminosity: 'light'})}` }}>Cold Dish | Appetizer</h1> : ''}
          {this.state.category === 'shark' ? <h1 style={{ fontWeight: 'bold', color: `${randomColor({ luminosity: 'light'})}` }}>Shark's Fin | Thick Soup</h1> : ''}
          {this.state.category === 'crab' ? <h1 style={{ fontWeight: 'bold', color: `${randomColor({ luminosity: 'light'})}` }}>Crab</h1> : ''}
          {this.state.category === 'prawn' ? <h1 style={{ fontWeight: 'bold', color: `${randomColor({ luminosity: 'light'})}` }}>Prawn</h1> : ''}
          {this.state.category === 'fish' ? <h1 style={{ fontWeight: 'bold', color: `${randomColor({ luminosity: 'light'})}` }}>Fish</h1> : ''}
          {this.state.category === 'meat' ? <h1 style={{ fontWeight: 'bold', color: `${randomColor({ luminosity: 'light'})}` }}>Meat</h1> : ''}
          {this.state.category === 'vegetable' ? <h1 style={{ fontWeight: 'bold', color: `${randomColor({ luminosity: 'light'})}` }}>Vegetable</h1> : ''}
          {this.state.category === 'tofu' ? <h1 style={{ fontWeight: 'bold', color: `${randomColor({ luminosity: 'light'})}` }}>Tofu</h1> : ''}
          {this.state.category === 'rice' ? <h1 style={{ fontWeight: 'bold', color: `${randomColor({ luminosity: 'light'})}` }}>Rice & Noodles</h1> : ''}
          {this.state.category === 'dessert' ? <h1 style={{ fontWeight: 'bold', color: `${randomColor({ luminosity: 'light'})}` }}>Dessert | Drink | Beer | Wine</h1> : ''}
        </div>
        </Row>
      </Grid>}
      <Grid>
        <a href={pdfMenu} className={'menuLink'}><div style={{boxShadow: `6px 3px 3px ${randomColor({ luminosity: 'light'})}`}} className={'menuBtn'}>MENU</div></a>
      </Grid>
      {false && !gallery && <Grid id="anchor">
        <Row className="show-grid row">
          <Col xs={12} md={4}>
            <ul id='leftlist' style={{listStyle: 'none', textAlign: 'left', padding: '1em', fontSize: 18, boxShadow: `6px 3px 3px ${randomColor({ luminosity: 'light'})}`, border: 'solid 1px black'}}>
              {/* <li style={ this.state.category === 'familyB' ? { fontWeight: 'bold', color: `${randomColor({ luminosity: 'light'})}` }: {}} onClick={() => { this.dishlist('familyB')}}>Family Happiness Set (10pax@ $488)</li>
              <li style={ this.state.category === 'familyC' ? { fontWeight: 'bold', color: `${randomColor({ luminosity: 'light'})}` }: {}} onClick={() => { this.dishlist('familyC')}}>Good Luck Set (10pax@ $728)</li>
              <li style={ this.state.category === 'familyD' ? { fontWeight: 'bold', color: `${randomColor({ luminosity: 'light'})}` }: {}} onClick={() => { this.dishlist('familyD')}}>Rich Years Set (10pax@ $1088)</li> */}
              <li style={ this.state.category === 'cold' ? { fontWeight: 'bold', color: `${randomColor({ luminosity: 'light'})}` }: {}} onClick={() => { this.dishlist('cold')}}>Cold Dish | Appetizer</li>
              <li style={ this.state.category === 'shark' ? { fontWeight: 'bold', color: `${randomColor({ luminosity: 'light'})}` }: {}} onClick={() => { this.dishlist('shark')}}>Shark's Fin | Thick Soup</li>
              <li style={ this.state.category === 'crab' ? { fontWeight: 'bold', color: `${randomColor({ luminosity: 'light'})}` }: {}} onClick={() => { this.dishlist('crab')}}>Crab</li>
              <li style={ this.state.category === 'prawn' ? { fontWeight: 'bold', color: `${randomColor({ luminosity: 'light'})}` }: {}} onClick={() => { this.dishlist('prawn')}}>Prawn</li>
              <li style={ this.state.category === 'fish' ? { fontWeight: 'bold', color: `${randomColor({ luminosity: 'light'})}` }: {}} onClick={() => { this.dishlist('fish')}}>Fish</li>
              <li style={ this.state.category === 'meat' ? { fontWeight: 'bold', color: `${randomColor({ luminosity: 'light'})}` }: {}} onClick={() => { this.dishlist('meat')}}>Meat</li>
              <li style={ this.state.category === 'vegetable' ? { fontWeight: 'bold', color: `${randomColor({ luminosity: 'light'})}` }: {}} onClick={() => { this.dishlist('vegetable')}}>Vegetable</li>
              <li style={ this.state.category === 'tofu' ? { fontWeight: 'bold', color: `${randomColor({ luminosity: 'light'})}` }: {}} onClick={() => { this.dishlist('tofu')}}>Tofu</li>
              <li style={ this.state.category === 'rice' ? { fontWeight: 'bold', color: `${randomColor({ luminosity: 'light'})}` }: {}} onClick={() => { this.dishlist('rice')}}>Rice & Noodles</li>
              <li style={ this.state.category === 'dessert' ? { fontWeight: 'bold', color: `${randomColor({ luminosity: 'light'})}` }: {}} onClick={() => { this.dishlist('dessert')}}>Dessert | Drink | Beer | Wine</li>
            </ul>
          </Col>
          <Col xs={12} md={8}>
            {/* {this.state.dish && this.state.dish.map((dish) => {
              return <Dish dish={dish}/>
            })} */}
          </Col>
        </Row>
      </Grid>}
      {gallery && <Gallery id="anchor2" />}

          {/* {this.state.modalPop && <div className={'pdfObject'}>
            <span className={'closedModal'} onClick={() => {this.modalPop()}}>CLOSE</span>
            <object 
              type="application/pdf"
              data={pdfMenu}
              width="100%"
              height="100%">
            </object>
          </div>} */}
      </div>
    )
  }

}

export default Home
