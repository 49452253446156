import React, { Component } from 'react'
import logo from './logo.svg'
import './App.css'
import { Navbar, Nav, MenuItem, NavDropdown, NavItem, Modal, Popover, Tooltip, Button, OverlayTrigger, Grid } from 'react-bootstrap'
import {
  BrowserRouter as Router,
  Route,
  NavLink
} from 'react-router-dom'
import Home from './components/home'
import Menu from './components/menu'
import Contact from './components/contact'

class App extends Component {

  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    return (
      <Router>
        <div className='App'>
          <Route exact path='/' component={Home} />
        </div>
      </Router>
    )
  }
}

export default App
