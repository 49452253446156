import React, { Component } from 'react'

class Menu extends Component {

  render () {

        let menu = ['https://media-cdn.tripadvisor.com/media/photo-s/09/e9/72/c7/photo2jpg.jpg', 'http://i.hungrygowhere.com/business/1f/bd/11/00/golden-spoon.jpg', 'http://www.asianex.biz/wp-content/uploads/2017/02/goldenspoon3.jpg', 'http://i.hungrygowhere.com/business/20/bd/11/00/golden-spoon.jpg', 'https://wheremytummycomesfrom.files.wordpress.com/2013/04/img_6246.jpg', 'http://3.bp.blogspot.com/-uYitJBrRKZY/T-hzHwz5-ZI/AAAAAAAAhwg/ifKvdguGY3U/s1600/prawn+roll.JPG', 'https://s.yimg.com/ny/api/res/1.2/VALBFIzPEil338U83CJ0Cw--/YXBwaWQ9aGlnaGxhbmRlcjtzbT0xO3c9ODAw/http://media.zenfs.com/en-SG/homerun/sethlui/7f4930bf9e0980fbc3e0c350e59b89dd', 'http://wheretoeatsg.com/images/joomd/13794021034.jpg', 'https://media-cdn.tripadvisor.com/media/photo-s/01/e8/1f/8f/the-3-eggs-spinach-7.jpg', 'http://i.hungrygowhere.com/business/21/bd/11/00/golden-spoon.jpg', 'https://s3-media2.fl.yelpcdn.com/bphoto/wHjOWYodyygLRpreprORUQ/ls.jpg', 'https://media-cdn.tripadvisor.com/media/photo-s/0a/b3/22/f2/photo0jpg.jpg', 'https://s.yimg.com/ny/api/res/1.2/tcXEEQu2mLT8dzEouOOAXg--/YXBwaWQ9aGlnaGxhbmRlcjtzbT0xO3c9NjMwO2g9NDczO2lsPXBsYW5l/http://media.zenfs.com/en_SG/News/Makansutra/Salted-egg-pork-ribs.jpg', 'https://s.yimg.com/ny/api/res/1.2/6xzyQCeo293r62i6sT87cg--/YXBwaWQ9aGlnaGxhbmRlcjtzbT0xO3c9NjMwO2g9NDczO2lsPXBsYW5l/http://media.zenfs.com/en_SG/News/Makansutra/Deep-fried-baby-squid.jpg']

    return (
      <div className='menu-main'>
    <div>
      {menu.map(function (food) {
        return <div style={{background: "url('" + food + "') no-repeat center", height: '18em', width: '18em', backgroundSize: 'cover', borderRadius: '1em', border: 'rgba(170, 165, 162, 0.31) 0.2px solid', display: "inline-block", margin: "1em"}} />
      })}
    </div>
  </div>
    )
  }

}

export default Menu
