import React from 'react'

class Contact extends React.Component {

  render () {
    return (
      <div>
        Contact
      </div>
    )
  }

}

export default Contact
