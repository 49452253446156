import React, { Component } from 'react';
import randomColor from 'randomcolor';

class Dish extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const color = randomColor({ luminosity: 'light', hue: 'green' })
    const color2 = randomColor({ luminosity: 'light', hue: 'green' })
    const { dish } = this.props
    return (
      <div className='dishlist' style={{ backgroundColor: `${color}`, boxShadow: `12px 12px 2px 1px rgba(134, 134, 134, 0.53)`}}>
        <div>
          {dish && dish.name}
        </div>
        <div>
          {dish && dish.price && dish.price.map((price) => <span style={{ padding: '1em'}}>{price}</span>)}
        </div>
      </div>
    );
  }

}

export default Dish;
